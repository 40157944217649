.tinymce-container {
  position: relative;
  min-height: 200px;
}

.tinymce-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
