// Montserrat Light
@font-face{
  font-family: Montserrat;
  src: url('../fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

// Montserrat Regular
@font-face{
  font-family: Montserrat;
  src: url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// Montserrat Medium
@font-face{
  font-family: Montserrat;
  src: url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// Montserrat Bold
@font-face{
  font-family: Montserrat;
  src: url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
