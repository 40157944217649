.devise {
  .container-fluid {
    margin-top: 50px;
    max-width: 344px;
    padding: 0;
  }

  h1 {
    color: $text-color;
    font-size: 20px;
    margin: 0;
    padding-top: 15px;
    text-align: center;
  }

  .card {
    @include media-breakpoint-up(sm) {
      box-shadow: 0 1px 5px $box-shadow;
    }

    @include media-breakpoint-down(sm) {
      border: none;
    }
  }

  .card-title {
    background-color: $primary;
    box-shadow: 0 1px 5px $box-shadow;
    margin-bottom: 0;
    margin-top: -2.4rem;
    padding: 15px;
    position: relative;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }

  .card-body {
    padding: 10px;
  }

  .card-footer {
    background-color: unset;
    padding: 10px;
  }

  .custom-error {
    color: #dc3545;
    font-size: 80%;
  }
}
