/* Für Webkit-Browser wie Chrome, Safari */
.input_item_transaction_quantity::-webkit-inner-spin-button,
.input_item_transaction_quantity::-webkit-outer-spin-button,
.input_storage_quantity::-webkit-inner-spin-button,
.input_storage_quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Für Mozilla Firefox */
.input_item_transaction_quantity,
.input_storage_quantity {
  -moz-appearance: textfield;
}

@media screen and (max-width: 600px) {

  .table-products-list-view tr,
  .table-products-list-view td {
    display: block;
  }

  .table-products-list-view thead {
    display: none;
  }

  .table-products-list-view tr {
    display: grid;
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .table-products-list-view td {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: center;
    border-bottom: none;
  }

  .table-products-list-view td a {
    width: 100%;
    padding: 0.75em 2em 0.75em 0.75em;
    margin: 0;
    text-align: center;
  }

  .table-products-list-view td:last-child {
    border-bottom: 1px solid #dee2e6;
  }

  .table-products-list-view td i.fa-check {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: 100%;
    display: grid;
    align-content: center;
    padding: 0.75em 0.35em;
  }

  .table-products-list-view td div {
    width: 100%;
  }

  .table-products-list-view td::before {
    content: attr(data-label);
    display: flex;
    align-items: center;
    min-width: 40%;
    text-align: left;
    background-color: #fbfbfb;
    color: #464a4c;
    border-right: 1px solid #f2f2f2;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0.75em;
    font-size: 0.80rem;
    font-weight: bold;
  }
}
