.target-quantity-container {
  &.edited {
    background-color: $box-shadow;
  }

  .edit-container {
    width: 150px;
  }

  .show-container {
    width: max-content;
    float: right;
  }

  i {
    margin: 0;
  }
}