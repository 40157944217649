#camera-scanner .viewport {
  display: inline-block;
  position:relative;
  width: 100%;
  height: 485px;
  margin: 0 auto;
  background-color: #6c757d;
  border: 1px solid rgb(69, 176, 255);
  overflow: hidden;
}

#camera-scanner .viewport .alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

#camera-scanner .viewport .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#camera-scanner .viewport #detection-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#camera-scanner .viewport #camera-feed {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

.camera-scanner-toggle-btn {
  padding: 0;
}
