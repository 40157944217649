.application {
  .navigation-trigger {
    cursor: pointer;
    float: left;
    padding: 2rem 2rem 2rem 2.4rem;
    margin-left: -2rem;
  }

  .navigation-trigger__inner {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      left: -11px;
      top: -14px;
      background-color: rgba(255, 255, 255, 0.25);
      border-radius: 50%;
      -webkit-transition: all 300ms;
      transition: all 300ms;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }

  .navigation-trigger__line {
    height: 2px;
    background-color: #FFFFFF;
    display: block;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .navigation-trigger.toggled .navigation-trigger__inner {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .navigation-trigger__inner,
  .navigation-trigger__line {
    width: 18px;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }

  .navigation-trigger.toggled .navigation-trigger__line:first-child {
    width: 12px;
    -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
    transform: translateX(8px) translateY(1px) rotate(45deg);
  }

  .navigation-trigger.toggled .navigation-trigger__line:last-child {
    width: 11px;
    -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
    transform: translateX(8px) translateY(-1px) rotate(-45deg);
  }

  .header {
    position: fixed;
    width: 100%;
    height: 72px;
    -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    padding: 0 2rem;
    z-index: 11;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgb(69, 176, 255);

    a.dropdown-toggle {
      color: #fff;
    }

    .backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 8;
    }
  }

  .navbar-brand img {
    width: 90px;
    vertical-align: baseline;
  }

  .dropdown-menu {
    z-index: 100000;
  }

  main {
    @include media-breakpoint-down(lg) {
      padding: 80px 0 0 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 90px 0 0 270px;
    }

    header {
      padding: 2rem 1rem 0;
      margin-bottom: 2rem;
      position: relative;

      h1 {
        line-height: 100%;
        font-weight: normal;
        font-size: 1.15rem;
        margin: 0;
        text-transform: uppercase;
        color: #676767;
      }
    }

    article {
      margin: 0 1rem;
    }
  }

  article.card {
    border: none;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
    margin-bottom: 30px;
  }

  .flash-messages-container {
    .alert {
      position: fixed;
      top: 105px;
      z-index: 9;
      width: 100%;
      right: 0;
      max-width: 30%;
      border-radius: 0;
      -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
      text-align: left;

      padding: 18px 24px;
      margin-bottom: 1rem;
      border: 1px solid transparent;

      @include media-breakpoint-down(md) {
        top: 96px;
        max-width: 100%;
      }

      @include media-breakpoint-up(md) {
        max-width: 45%;
        padding: 18px 18px;
      }
    }

    .fade.show {
      opacity: 1;
    }

    .alert {
      font-weight: 500;
    }

    .alert-success {
      background-color: #57d59f;
      border-color: #57d59f;
      color: #FFFFFF;
    }

    .alert-dismissible .close {
      position: relative;
      top: 0;
      right: -24px;
      padding: 0 24px;
      color: inherit;

      &:focus {
        outline: none;
      }
    }
  }

  .sidebar {
    width: 270px;
    position: fixed;
    left: 0;
    padding: 110px 1.5rem 1rem;
    height: 100%;
    z-index: 10;

    @include media-breakpoint-down(md) {
      background-color: #FFFFFF;
      -webkit-transition: opacity 300ms, -webkit-transform 300ms;
      transition: opacity 300ms, -webkit-transform 300ms;
      transition: transform 300ms, opacity 300ms;
      transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
      -webkit-box-shadow: 5px 0 10px rgba(0, 0, 0, 0.08);
      box-shadow: 5px 0 10px rgba(0, 0, 0, 0.08);

      &:not(.toggled) {
        opacity: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }

    &.toggled {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .scroll-wrapper {
      position: relative;
      height: 100%;
      // overflow: hidden;
      padding: 0;
      position: relative;
    }

    .scrollbar-inner {
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .navigation {
      list-style: none;
      padding: 0;
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .navigation>li>a {
      padding: 0.85rem 0.5rem;
      position: relative;
      border-radius: 2px;
      transition: background-color 300ms, color 300ms;
      font-weight: 500;
      display: block;
    }

    a {
      color: #707070;
      cursor: pointer;
      text-decoration: none;
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
    }

    .active>a {
      color: rgb(69, 176, 255) !important;
    }

    .sidebar-card {
      background-color: rgba(0, 0, 0, 0.03);
      border: none;
      margin-bottom: 20px;

      .dropdown-toggle::after {
        content: none;
      }

      .dropdown-toggle {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .dropdown-menu {
        max-width: 97.5vw;
        max-height: 96vh;
        overflow-y: auto;
      }

      .dropdown-item {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .trigger-search-wrapper {
    margin: 0;
    padding: 0;
    width: calc(100% - 27px);
  }

  .table-wrapper {
    white-space: nowrap;
  }

  .with-nowrap {
    white-space: normal;
  }

  .tooltip-inner   {
    max-width: fit-content;
    text-align: left;
  }

  .table-scrollable {
    overflow: auto;
    // max-height: 520px;
    position: relative;
    margin-top: 40px;

    thead th {
      position: sticky;
      top: 0;
      z-index: 3;
    }

    tbody th {
      position: sticky;
      left: 0;
    }
  }

  .table-actions {
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      justify-content: space-around;
      white-space: normal;
    }
  }

  .table-info {
    margin-top: 2em;
    text-align: center;
    margin-bottom: 25px;
    font-size: 0.9rem;
    color: #9c9c9c;
    background-color: transparent;
  }

  table.table {
    margin-top: 40px;
  }

  ul.pagination {
    justify-content: center;
  }

  tr.active {
    background-color: $primary  !important;
    color: #fff;

    td:not(.not-selectable) {
      background-color: $primary;
    }
  }

  table:not(.not-selectable) td:not(.not-selectable), .selectable {
    cursor: pointer;
  }

  .table-wrapper.not-selectable td {
    cursor: default !important;
  }

  .table thead th {
    color: #464a4c;
    background-color: #fbfbfb;
    border: 1px solid #f2f2f2;
  }

  .bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
  .bootstrap-select .dropdown-toggle:focus {
    border-color: #9acffa;
    outline: unset !important;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.25);
  }

  .bootstrap-select>.dropdown-toggle {
    border: 1px solid #ced4da;
    background-color: #fff;
  }

  i {
    margin-right: 5px;
  }

  .clear-search i,
  .btn-increase i,
  .btn-decrease i,
  .clear-number i {
    margin: 0;
  }

  .image-center {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 20px
  }

  .image-empty {
    height: 64px;
    display: block;
  }

  .text-center a {
    text-align: center;
  }

  .ui-sortable-handle {
    cursor: pointer;
  }

  .refrigerator-handler {
    margin-top: 32px;
  }

  .d-flex {
    .btn+.btn {
      border-left: 1px solid #fff;
    }
  }

  .reversable {
    @include media-breakpoint-down(sm) {
      flex-flow: column-reverse;
    }
  }

  td.not-selectable {
    background-color: #fff;
  }

  .activities td p {
    margin: 0;

    &+p {
      margin-top: 1rem;
    }
  }

  button.disabled {
    background-color: #e9ecef !important;
    opacity: 1;
  }

  .select .dropdown-menu a {
    padding: 0;

    .text:empty {
      display: none;
    }

    .text {
      padding: 0.25rem 1.5rem;
    }
  }

  .placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 15px);
  }

  .copy-to-clipboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tox.tox-tinymce {
    height: 400px !important;
  }

  .placeholders-list {
    max-height: 540px;
    overflow: scroll;
  }

  .text-info {
    color: #03A9F4 !important;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 15;
    top: 0;
    align-items: center;
    justify-content: center;
    display: none;
  }
}

.title-with-text-decoration {
  overflow: hidden;
  text-align: center;
}

.title-with-text-decoration:before,
.title-with-text-decoration:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.title-with-text-decoration:before {
  right: 0.5em;
  margin-left: -50%;
}

.title-with-text-decoration:after {
  left: 0.5em;
  margin-right: -50%;
}

.rich_text_area {
  height: 100%;
}

@media screen and (max-width: 600px) {
  .alert {
    display: inline-block;
  }

  table.mobile-friendly tr,
  table.mobile-friendly td {
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  table.mobile-friendly thead {
    display: none;
  }

  table.mobile-friendly tr {
    display: grid;
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  table.mobile-friendly td {
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
    justify-content: center;
    border: 1px solid #dee2e6;
    border-bottom: none;
  }

  table.mobile-friendly td .content {
    width: 60%;
    padding: 0.75em;
    margin: 0;
  }

  table.table.mobile-friendly td:last-child {
    border-bottom: 1px solid #dee2e6;
  }

  table.mobile-friendly td {
    width: 100%;
    max-width: 100%;
  }

  table.mobile-friendly td::before {
    content: attr(data-label);
    display: flex;
    align-items: center;
    min-width: 40%;
    width: 40%;
    text-align: left;
    background-color: #fbfbfb;
    color: #464a4c;
    border-right: 1px solid #f2f2f2;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0.75em;
    font-size: 0.80rem;
    font-weight: bold;
  }

  table.mobile-friendly .form-actions.d-flex {
    flex-direction: column;
  }

  table.mobile-friendly .form-actions.d-flex .btn {
    margin: 1rem 0 0 0 !important;
    border: 0;
  }

  table.mobile-friendly pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .table-actions.mobile-friendly .btn {
    width: 100%;
    background-color: rgb(69, 176, 255);
    border-color: rgb(69, 176, 255);
    color: #fff;
    margin-top: 1rem;
  }
}