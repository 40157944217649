.datepicker table.table {
  margin-top: 0;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 16rem;
}

[data-toggle="datetimepicker"] {
  cursor: pointer;
}
