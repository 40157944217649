#photo-capture-modal {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

#photo-capture-modal .modal-dialog {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

#photo-capture-modal .modal-content {
  height: 100%;
  border: none;
}

#photo-capture-modal .modal-content .modal-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  border: none;
  z-index: 2000;
}

#photo-capture-modal .modal-content .modal-body {
  height: 100%;
  padding: 0;
}

#photo-capture-modal .modal-content .modal-header .modal-title {
  color: #fff;
  font-size: 1.2rem;

}

#photo-capture-modal .modal-content .modal-header .close {
  background-color: #6c757d;
  color: #fff;
  font-size: 2rem;
  opacity: 1;
}

#photo-capture-modal .modal-content .modal-body .modal-body-inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#photo-capture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #6c757d;
}

#photo-capture .viewport {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}

#photo-capture .viewport .alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

#photo-capture .viewport .video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#photo-capture .viewport #photo-capture-camera-feed {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

#photo-capture .viewport #photo-capture-canvas,
#photo-capture .viewport #photo-capture-canvas-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
}

#photo-capture .controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

#photo-capture .controls .btn {
  border: none;
}
