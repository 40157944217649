.pdf {
  font-size: 16px;
  background: #fff;
  color: #212529;
  font-family: inherit;

  .alwaysbreak {
    page-break-before: always;
  }

  .nobreak:before {
    clear:both;
  }

  .nobreak {
    page-break-inside: avoid;
  }

  address {
    margin-bottom: 50px;
  }

  thead { display: table-header-group }
  tfoot { display: table-row-group }
  tr { page-break-inside: avoid }
}
